import React, { useContext, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ProgressBar } from "react-bootstrap";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import storage from "../firebase";
import uuid from "react-uuid";
import AuthContext from "../AuthContext";
import { checkLoggedIn } from "../util/util";
import "./Drop.css";

const Drop = () => {
  const [dragActive, setDragActive] = useState(false);
  const [progress, setProgress] = useState(0);
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const inputRef = useRef(null);

  useEffect(() => {
    checkLoggedIn(authContext, navigate);
  }, []);

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleUpload(e.dataTransfer.files[0]);
    }
  };

  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleUpload(e.target.files[0]);
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  const handleUpload = (file) => {
    if (!file) {
      alert("Please choose a file first!");
      return;
    }

    const videoId = uuid();
    localStorage.setItem("videoId", videoId);

    const storageRef = ref(
      storage,
      `/videos/${authContext.userInfo.uuid}/${videoId}/${file.name}`
    );
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        // update progress
        setProgress(percent);
      },
      (err) => console.log(err),
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          localStorage.setItem("videoURL", url);
          navigate(`/create?name=${file.name}`);
        });
      }
    );
  };

  if (progress > 0) {
    return (
      <div
        className="d-flex justify-content-center flex-column align-items-center"
        style={{ marginTop: "25vh" }}
      >
        <h5 className="fw-bold">uploading...</h5>
        <ProgressBar
          variant="dark"
          className="mt-3 border border-3 border-dark"
          style={{ width: "35vw" }}
          now={progress}
          label={`${progress.toFixed(2)}%`}
        />
      </div>
    );
  }

  return (
    <div
      className="d-flex justify-content-center"
      style={{ marginTop: "15vh" }}
    >
      <form
        id="form-file-upload"
        onDragEnter={handleDrag}
        onSubmit={(e) => e.preventDefault()}
      >
        <input
          ref={inputRef}
          type="file"
          id="input-file-upload"
          multiple={true}
          onChange={handleChange}
          accept=".mp4, .mov"
        />
        <label
          id="label-file-upload"
          htmlFor="input-file-upload"
          className={dragActive ? "drag-active" : ""}
        >
          <div>
            <div className="d-flex flex-column fs-1">
              <h1>
                <i className="bi bi-upload" style={{ fontSize: 80 }} />
              </h1>
              <p id="upload-here">Drag a video here!</p>
            </div>
            <button className="upload-button" onClick={onButtonClick}>
              Upload a file
            </button>
          </div>
        </label>
        {dragActive && (
          <div
            id="drag-file-element"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          ></div>
        )}
      </form>
    </div>
  );
};

export default Drop;
