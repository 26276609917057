import React, { useContext, useEffect, useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import AuthContext from "../AuthContext";
import { checkLoggedIn } from "../util/util";
import { ref, getDownloadURL, listAll } from "firebase/storage";
import storage from "../firebase";
import { Spinner } from "react-bootstrap";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import FileSaver from "file-saver";
import "./Home.css";

const Home = () => {
  const [videoList, setVideoList] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [clipMap, setClipMap] = useState(new Map());
  const [loading, setLoading] = useState(true);
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    checkLoggedIn(authContext, navigate);
  }, []);

  useEffect(() => {
    getVideos();
  }, [authContext.userInfo]);

  useEffect(() => {
    setFilterList(
      videoList.filter((video) => video.name.includes(authContext.searchVal))
    );
  }, [authContext.searchVal]);

  useEffect(() => {
    setFilterList(videoList);
    if (videoList.length > 0) {
      setLoading(false);
    }
  }, [videoList]);

  const updateMap = (k, v) => {
    setClipMap(new Map(clipMap.set(k, v)));
  };

  const handleZip = (clips, videoName) => {
    let zip = new JSZip();
    let count = 0;
    clips.forEach((clip) => {
      // loading a file and add it in a zip file
      JSZipUtils.getBinaryContent(clip.url, (err, data) => {
        if (err) {
          alert("Sorry could not download clips");
        }
        zip.file(clip.name, data, { binary: true });
        count++;
        if (count === clips.length) {
          zip.generateAsync({ type: "blob" }).then(function (content) {
            FileSaver.saveAs(content, `${videoName.split(".")[0]}_clips.zip`);
          });
        }
      });
    });
  };

  const handleClipDownload = (e, videoId, videoName) => {
    e.preventDefault();
    const clips = clipMap.get(videoId);
    handleZip(clips, videoName);
  };

  const getClips = async (videoId) => {
    let tempList = [];
    const storageRef = ref(
      storage,
      `videos/${authContext.userInfo.uuid}/${videoId}`
    );
    const videoDir = await listAll(storageRef);
    if (videoDir.items.length > 0) {
      const mainName = videoDir.items[0]._location.path_.split("/").at(-1);
      const mainUrl = await getDownloadURL(videoDir.items[0]);
      tempList.push({ name: mainName, url: mainUrl, clip: false });
      if (videoDir.prefixes.length > 0) {
        const clipDir = await listAll(videoDir.prefixes[0]);
        for (let ind in clipDir.items) {
          const name = clipDir.items[ind]._location.path_.split("/").at(-1);
          const url = await getDownloadURL(clipDir.items[ind]);
          tempList.push({ url: url, name: name, clip: true });
        }
      }
    }
    updateMap(
      videoId,
      tempList.filter((data) => data.clip)
    );
  };

  const getVideos = async () => {
    const storageRef = ref(storage, `videos/${authContext.userInfo.uuid}`);
    const videoDirs = await listAll(storageRef);
    if (videoDirs.prefixes.length < 1) {
      setLoading(false);
    }
    let urlList = [];
    for (let ind in videoDirs.prefixes) {
      const video = await listAll(videoDirs.prefixes[ind]);
      for (let i in video.items) {
        const name = video.items[i]._location.path_.split("/").at(-1);
        const videoId = video.items[i]._location.path_.split("/").at(-2);
        getClips(videoId);
        const url = await getDownloadURL(video.items[i]);
        urlList.push({ url, name, videoId });
      }
    }
    setVideoList(urlList);
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center flex-column align-items-center mt-5">
        <h1>Welcome to Sharetape</h1>
        <h2>Loading Projects...</h2>
        <Spinner />
      </div>
    );
  }

  return (
    <div className="d-flex flex-row flex-wrap">
      <div
        onClick={() => navigate("/drop")}
        style={{ cursor: "pointer" }}
        className="d-flex flex-column align-items-center mx-5 my-5 create-clips"
      >
        <h1 style={{ fontSize: "200px" }}>
          <i className="bi bi-plus" />
        </h1>
        <h3 className="mb-5">Create clips</h3>
      </div>
      {filterList?.map((item, ind) => (
        <div
          className="mx-5 my-5"
          style={{
            border: "6px solid black",
            borderRadius: "6px",
          }}
          key={ind}
        >
          <NavLink
            to={`/clips?videoId=${item.videoId}`}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <video src={item.url} className="video" />
          </NavLink>
          <div className="d-flex flex-row justify-content-between">
            <div className="d-flex flex-row justify-content-evenly flex-grow-1 pt-2">
              <h5 className="fw-bold">
                {item.name.split(".")[0].length > 12
                  ? `${item.name.split(".")[0].substring(0, 11)}...`
                  : item.name.split(".")[0]}
              </h5>
              <h5 className="text-secondary">
                {clipMap.get(item.videoId)?.length | "0"} clips
              </h5>
            </div>
            <div className="d-flex flex-row">
              <div
                className="d-flex align-items-center border-2 border-start border-dark"
                style={{ backgroundColor: "#FAFFDC", cursor: "pointer" }}
                onClick={(e) => handleClipDownload(e, item.videoId, item.name)}
              >
                <i className="bi bi-download px-2" />
              </div>
              <NavLink
                to={`/clips?videoId=${item.videoId}`}
                style={{ textDecoration: "none", color: "inherit" }}
                className="d-flex"
              >
                <div
                  className="d-flex align-items-center border-2 border-start border-dark"
                  style={{ backgroundColor: "#DCFFE2" }}
                >
                  <i className="bi bi-arrows-angle-expand px-2" />
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Home;
