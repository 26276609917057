import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ref, getDownloadURL, listAll, deleteObject } from "firebase/storage";
import storage from "../firebase";
import { useNavigate } from "react-router-dom";
import AuthContext from "../AuthContext";
import { checkLoggedIn } from "../util/util";
import { Spinner } from "react-bootstrap";
import ReactPlayer from "react-player";

const Clips = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [videoList, setVideoList] = useState([]);
  const [currentVideo, setCurrentVideo] = useState("");
  const [currentName, setCurrentName] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    checkLoggedIn(authContext, navigate);
  }, []);

  useEffect(() => {
    if (!searchParams.has("videoId")) {
      navigate("/home");
    }
    const videoId = searchParams.get("videoId");
    getClips(videoId);
  }, [authContext.userInfo]);

  useEffect(() => {
    if (videoList.length > 0) {
      setCurrentName(videoList[0].name);
    }
  }, [videoList]);

  const getClips = async (videoId) => {
    let tempList = [];
    const storageRef = ref(
      storage,
      `videos/${authContext.userInfo.uuid}/${videoId}`
    );
    const videoDir = await listAll(storageRef);
    if (videoDir.items.length > 0) {
      const mainName = videoDir.items[0]._location.path_.split("/").at(-1);
      const mainUrl = await getDownloadURL(videoDir.items[0]);
      tempList.push({ name: mainName, url: mainUrl, clip: false });
      if (videoDir.prefixes.length > 0) {
        const clipDir = await listAll(videoDir.prefixes[0]);
        for (let ind in clipDir.items) {
          const name = clipDir.items[ind]._location.path_.split("/").at(-1);
          const url = await getDownloadURL(clipDir.items[ind]);
          tempList.push({ url: url, name: name, clip: true });
        }
      }
    }

    setVideoList(tempList);
    tempList.length > 0 && setCurrentVideo(tempList[0].url);
    setLoading(false);
  };

  const handleClick = (video) => {
    setCurrentVideo(video.url);
    setCurrentName(video.name);
  };

  const handleDelete = () => {
    const response = window.confirm(
      "Are you sure you want to delete this project?"
    );
    if (response === true) {
      const videoId = searchParams.get("videoId");
      deleteFolder(`videos/${authContext.userInfo.uuid}/${videoId}`);
    }
  };

  const deleteFolder = (path) => {
    const storageRef = ref(storage, path);
    listAll(storageRef)
      .then((dir) => {
        dir.items.forEach((fileRef) =>
          deleteFile(storageRef.fullPath, fileRef.name)
        );
        dir.prefixes.forEach((folderRef) => deleteFolder(folderRef.fullPath));
      })
      .catch((error) => console.log(error));
  };

  const deleteFile = (pathToFile, fileName) => {
    const storageRef = ref(storage, `${pathToFile}/${fileName}`);
    deleteObject(storageRef)
      .then(() => {
        console.log(`successfully deleted "${pathToFile}/${fileName}"`);
      })
      .catch((error) => {
        console.log(error);
      });
    navigate("/home");
  };

  if (loading) {
    return (
      <>
        <div className="d-flex justify-content-center flex-column align-items-center mt-5">
          <h2>Loading Clips...</h2>
          <Spinner />
        </div>
      </>
    );
  }

  return (
    <div className="d-flex flex-row">
      <h3
        className="ms-2 mt-2 fw-bold"
        onClick={() => navigate("/home")}
        style={{ cursor: "pointer" }}
      >
        {"< Back"}
      </h3>
      <div
        style={{ margin: "20vh 28vw 0 0" }}
        className="d-flex flex-grow-1 justify-content-center"
      >
        <div className="d-flex flex-column justify-content-center border border-5 border-dark rounded">
          <ReactPlayer url={currentVideo} controls={true} />
          <div className="d-flex flex-row justify-content-between">
            <div className="ms-1">
              {currentName.replace(searchParams.get("videoId") + "_", "")}
            </div>
            <div className="d-flex flex-row">
              <div
                className="px-2 border-end border-start border-2 border-dark py-0 fw-bold"
                style={{
                  cursor: "pointer",
                  backgroundColor: "#D64933",
                }}
                onClick={handleDelete}
              >
                Delete Project
              </div>
              <a
                href={currentVideo}
                download
                className="px-2"
                style={{
                  all: "unset",
                  cursor: "pointer",
                  backgroundColor: "#FAFFDC",
                  fontWeight: 900,
                }}
              >
                <i className="bi bi-download" /> Download
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          maxHeight: "100vh",
          overflowY: "scroll",
          backgroundColor: "#3C423D",
          position: "absolute",
          width: "450px",
          right: 0,
          borderLeft: "6px solid black",
        }}
        className="d-flex flex-column ps-5"
      >
        <div className="text-center">
          <h2 className="text-white fw-bold mb-3 mt-1">Select a video</h2>
        </div>
        {videoList.map((item, ind) => (
          <div key={ind} onClick={() => handleClick(item)}>
            <div className="text-white">
              {!item.clip && "Full video: "}{" "}
              {item.name.replace(searchParams.get("videoId") + "_", "")}
            </div>
            <video
              src={item.url}
              height={200}
              className="mb-1 rounded-1"
              style={{
                border: item.url === currentVideo ? "6px solid #DCFFE1" : "",
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Clips;
