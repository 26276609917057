import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: "sharetape-41fd3.firebaseapp.com",
  projectId: "sharetape-41fd3",
  storageBucket: "sharetape-41fd3.appspot.com",
  messagingSenderId: "337160218541",
  appId: "1:337160218541:web:4b376407a36f3222b2e324",
  measurementId: "G-MB0T0DVSK9",
};

const app = initializeApp(firebaseConfig);

const storage = getStorage(app);

export const auth = getAuth(app);
export default storage;
