import React from "react";

const WaitList = () => {
  return (
    <div className="d-flex justify-content-center text-center">
      <div>
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLScrhwBwQRpGGmJ3mM8b78Jdgwj-AjpDNi01Iow4ZYUrD-MAcA/viewform?embedded=true"
          width="640"
          height="800"
          style={{ frameBorder: 0, marginHeight: 0, marginWidth: 0 }}
        >
          Loading…
        </iframe>
      </div>
    </div>
  );
};

export default WaitList;
