import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, DropdownButton } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import AuthContext from "../AuthContext";
import { checkLoggedIn } from "../util/util";
import { ref, listAll } from "firebase/storage";
import storage from "../firebase";

const Create = () => {
  const [url, setURL] = useState("");
  const [videoId, setVideoId] = useState("");
  const [videoName, setVideoName] = useState("");
  const [clipLength, setClipLength] = useState(null);
  const [generating, setGenerating] = useState(false);
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    checkLoggedIn(authContext, navigate);
    setURL(localStorage.getItem("videoURL"));
    setVideoId(localStorage.getItem("videoId"));
    if (!searchParams.has("name")) {
      navigate("/home");
    }
    setVideoName(searchParams.get("name"));
  }, []);

  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const generateClips = () => {
    setGenerating(true);
    handlePost();
    alert("Clip generation can take up to 10min.");
    checkClips();
  };

  const handlePost = () => {
    const jsonData = {
      url: url,
      uuid: authContext.userInfo.uuid,
      videoId: videoId,
      clipLength: clipLength,
    };

    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      mode: "cors",
      body: JSON.stringify(jsonData),
    };

    fetch(`https://sharetape.ai/api/create-clips`, requestOptions).then(
      (response) => response.json()
    );
    // fetch(`http://localhost:5001/create-clips`, requestOptions).then(
    //   (response) => response.json()
    // );
    // .then((data) => console.log(data));
  };

  const checkClips = async () => {
    const storageRef = ref(
      storage,
      `videos/${authContext.userInfo.uuid}/${videoId}`
    );
    let clips = false;
    do {
      const videoDir = await listAll(storageRef);
      if (videoDir.prefixes.length > 0) {
        clips = true;
      }
      await sleep(1000);
    } while (!clips);

    await sleep(15000);
    navigate(`/clips?videoId=${videoId}`);
  };

  return (
    <div style={{ marginTop: "15vh" }}>
      {url !== "" && (
        <div className="d-flex flex-row justify-content-evenly">
          <div className="border border-5 border-dark rounded-4">
            <video
              src={url}
              style={{
                height: "454px",
                objectFit: "cover",
              }}
              className="rounded-top me-0"
            />
            <div className="d-flex justify-content-center mt-1 ms-1">
              <h5 className="fw-bold">{videoName}</h5>
            </div>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <DropdownButton
              variant="secondary"
              title={clipLength ? `${clipLength} seconds` : "Clip Length"}
              onSelect={(e) => setClipLength(e)}
            >
              <DropdownItem eventKey={30}>30 seconds</DropdownItem>
              <DropdownItem eventKey={60}>60 seconds</DropdownItem>
            </DropdownButton>
            <Button
              style={{
                backgroundColor: "#DCF2FF",
                border: "3px solid black",
                color: "black",
                fontWeight: 900,
              }}
              className="mt-5"
              disabled={!clipLength || generating}
              onClick={generateClips}
            >
              {generating ? "Generating..." : "Generate"}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Create;
