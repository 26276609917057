export const checkLoggedIn = (authContext, navigate) => {
  document.querySelector("body").style.backgroundColor =
    window.location.pathname === "/drop" ? "#DCF2FF" : "#ffffff";
  const storedUser = localStorage.getItem("user");
  const user = JSON.parse(storedUser);

  if (
    user &&
    user.hasOwnProperty("expirationTime") &&
    Date.now() < user.expirationTime
  ) {
    authContext.setUser(user.email, user.uuid, user.token, user.expirationTime);
    authContext.login();
    console.log("logged in successfully");
  } else {
    navigate("/");
  }
};
