import React, { useContext } from "react";
import { Navbar, Button } from "react-bootstrap";
import { signOut } from "firebase/auth";
import { auth } from "../firebase";
import { useNavigate, NavLink } from "react-router-dom";
import AuthContext from "../AuthContext";
import "./Navigation.css";

const Navigation = () => {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        authContext.logout();
        authContext.setUser({
          email: "",
          uuid: "",
          token: "",
          expirationTime: "",
        });
        localStorage.clear();
        navigate("/");
        console.log("Signed out successfully");
      })
      .catch((error) => {
        // An error happened.
        console.log(error);
      });
  };

  const handleSearch = (e) => {
    authContext.setSearchFilter(e.target.value);
  };

  return (
    <Navbar
      expand="lg"
      className="d-flex align-items-center justify-content-between px-4"
    >
      <NavLink
        to={window.location.pathname === "/waitlist" ? "/waitlist" : "/home"}
        style={{
          textDecoration: "none",
          color: "inherit",
          fontSize: 30,
          fontWeight: 900,
        }}
      >
        SHARETAPE
      </NavLink>

      <div className="d-flex flex-row align-content-center justify-content-center">
        {window.location.pathname === "/home" && (
          <div className="container-fluid d-flex justify-content-center">
            <div className="row d-flex justify-content-center align-content-center">
              <h5
                className="col-2"
                style={{ display: "inline-block", margin: "0 auto" }}
              >
                <i className="bi bi-search" />
              </h5>
              <input
                placeholder="Search & Filter"
                id="search"
                style={{ border: "none", outline: "none" }}
                className="col rounded-pill"
                onChange={handleSearch}
              />
            </div>
          </div>
        )}
        {authContext.isLoggedIn && (
          <Button
            variant="dark"
            className="rounded-pill py-1 px-3"
            onClick={handleLogout}
          >
            Logout
          </Button>
        )}
      </div>
    </Navbar>
  );
};

export default Navigation;
