import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import Home from "./pages/Home";
import Drop from "./pages/Drop";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import Clips from "./pages/Clips";
import Create from "./pages/Create";
import WaitList from "./pages/Waitlist";
import Navigation from "./components/Navigation";

import AuthContext from "./AuthContext";

const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [search, setSearch] = useState("");
  const [userInfo, setUserInfo] = useState({
    uuid: "",
    email: "",
    token: "",
    expirationTime: "",
  });

  const login = () => {
    setLoggedIn(true);
  };

  const logout = () => {
    setLoggedIn(false);
  };

  const setSearchVal = (s) => {
    setSearch(s);
  };

  const setUserVal = (email, uuid, token, expirationTime) => {
    setUserInfo({
      uuid,
      email,
      token,
      expirationTime,
    });
  };

  return (
    <Router>
      <AuthContext.Provider
        value={{
          isLoggedIn: loggedIn,
          login: login,
          logout: logout,
          setUser: setUserVal,
          setSearchFilter: setSearchVal,
          searchVal: search,
          userInfo: userInfo,
        }}
      >
        <div style={{ height: "100vh" }} className="d-flex flex-column">
          {window.location.pathname === "/clips" ? <></> : <Navigation />}
          <section>
            <Routes>
              <Route path="/" exact={true} element={<Login />} />
              <Route path="/home" exact={true} element={<Home />} />
              <Route path="/drop" exact={true} element={<Drop />} />
              <Route path="/create" exact={true} element={<Create />} />
              <Route path="/clips" exact={true} element={<Clips />} />
              {/* <Route path="/signup" exact={true} element={<Signup />} /> */}
              <Route path="/waitlist" exact={true} element={<WaitList />} />
              <Route path="*" element={<Navigate to="/home" replace />} />
            </Routes>
          </section>
          <p
            style={{
              marginTop: "auto",
            }}
            className="text-center"
          >
            © sharetape.ai
          </p>
        </div>
      </AuthContext.Provider>
    </Router>
  );
};

export default App;
