import React, { useContext, useState, useEffect } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";
import { NavLink, useNavigate } from "react-router-dom";
import AuthContext from "../AuthContext";
import { checkLoggedIn } from "../util/util";

const Login = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    checkLoggedIn(authContext, navigate);
  }, []);

  const onLogin = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        authContext.login();
        const tempUser = {
          uuid: user.uid,
          email: user.email,
          token: user.accessToken,
          expirationTime: user.stsTokenManager.expirationTime,
        };
        authContext.setUser(
          tempUser.email,
          tempUser.uuid,
          tempUser.token,
          tempUser.expirationTime
        );
        localStorage.setItem("user", JSON.stringify(tempUser));
        navigate("/home");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        if (errorMessage.includes("auth/wrong-password")) {
          alert("Wrong Password or Email");
        } else if (errorMessage.includes("auth/wrong-password")) {
          alert(
            "Access to this account has been temporarily " +
              "disabled due to many failed login attempts."
          );
        }
        console.log(errorCode, errorMessage);
      });
  };

  return (
    <>
      <main>
        <section>
          <div className="d-flex justify-content-center mt-5">
            <div>
              <h1> Sharetape </h1>
              <form>
                <div>
                  <label htmlFor="email-address">Email address</label>
                  <input
                    id="email-address"
                    name="email"
                    type="email"
                    required
                    placeholder="Email address"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <div>
                  <label htmlFor="password">Password</label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    required
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>

                <div>
                  <button onClick={onLogin}>Login</button>
                </div>
              </form>

              {/* <p>
                No account yet? <NavLink to="/signup">Sign up</NavLink>
              </p> */}
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Login;
